<template>
    <div>
        <!-- <div class="logo">
            <img src="/logo.png" alt="">
        </div> -->
        <div class="story">
          <a class="story-item" target="_blank" href="https://www.youtube.com/watch?v=9LX6HlS0yso">
              <div class="story-img" style="background-image: url('/story/musica-tema.png');"></div>
              <small><b>Música tema</b></small>
          </a>
          <a class="story-item" target="_blank" href="https://instagram.com/ejcsaojose?igshid=MzRlODBiNWFlZA==">
              <div class="story-img" style="background-image: url('/story/logo-ejc.png');"></div>
              <small><b>@ejcsaojose</b></small>
          </a>
          <a class="story-item" target="_blank" href="https://instagram.com/psjpb?igshid=MzRlODBiNWFlZA==">
              <div class="story-img" style="background-image: url('/story/psjpb.png');"></div>
              <small><b>@psjpb</b></small>
          </a>
        </div>
    </div>
</template>

<script>
export default {
  name: 'StoryList'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.logo {
    display: inline-block;
    img{
        width: 90px;
        height: 90px;
    }
}
.story{
    display: inline-block;
    // width: calc(100% - 8px);
    margin: 8px;
    .story-item{
        margin-right: 8px;
        display: inline-block;
        text-align: center;
    }
    .story-img{
        background-size: cover;
        background-position: center;
        border-radius: 50%;
        border: 3px solid #28e638;
        width: 90px;
        height: 90px;
    }
}

</style>
