<template>
  <CommentModal :post="selectedPost" :open="openComments" @fechar-modal="fecharModal" :user="user"/>
  <h4>Posts</h4>
  <div class="post" v-for="(post, idx) in posts" :key="'post-'+ post.key">
    <div class="post-header">
      <div>
        <div class="avatar" :style="`background-image: url('${getUserPhoto390URL(post.user?.photoURL)}'), url('${post.user?.photoURL}');`"></div>
      </div>
      <div>
        <div>
          <b>{{ post.user?.displayName }}</b>
          <img v-if="checkVerificado(post.user?.displayName)" class="icon" src="@/assets/verificado.png">
        </div>
        <div>
          <small>
            XI EJC SJ {{ post.user?.metadata?.equipe && "-" + post.user?.metadata?.equipe }}
          </small>
        </div>
      </div>
    </div>
    <!-- imagem -->
    <div @dblclick="() => like(post, idx)" class="post-img" :style="`background-image: url('${getPostPhoto800URL(post.img)}'), url('${getPostPhoto390URL(post.img)}')`"></div>
    <div class="post-actions">
      <img v-if="checkUserLiked(post.likes)" @click="() => like(post, idx)" src="@/assets/liked.png">
      <img v-if="!checkUserLiked(post.likes)" @click="() => like(post, idx)"  src="@/assets/like.png">
      <img src="@/assets/comment.png" @click="() => comentar(post)">
      <img src="@/assets/share.png"  @click="() => alert('OPS!! Função não permitida. Lembre-se do sigilo! ;)')">
    </div>
    <div class="post-descriptions">
      <small>
        <b>
          {{ post.likes && Object.keys(post.likes).length || '0' }} 
          <span v-if="post.likes && Object.keys(post.likes).length  === 1">curtida</span>
          <span v-else>curtidas</span>
        </b>
      </small><br>
      <small>
        <b>{{ post.user.displayName }}</b> {{post.text}}
      </small><br>
      <small @click="() => comentar(post)" v-if="post.comments && Object.keys(post.comments).length  >= 1">
        <b>
          {{ post.comments && Object.keys(post.comments).length || '0' }} 
          <span v-if="post.comments && Object.keys(post.comments).length  === 1">comentário</span>
          <span v-else>comentários</span>
        </b>
      </small>
    </div>
  </div>
  <div v-if="limit < 100" class="ver-mais" @click="more">Ver mais</div>

</template>

<script>
import { getDatabase, ref, child, query, orderByChild, get, limitToLast, set, remove, onValue } from "firebase/database";
import CommentModal from './CommentModal.vue'

export default {
  name: 'PostList',
  components: {
    CommentModal
  },
  props: {
    user: Object
  },
  data () {
    return {
      posts: {},
      selectedPost: null,
      openComments: false,
      limit: 20
    }
  },
  methods: {
    comentar(post) {
      this.selectedPost = post;
      this.openComments = true;
    },
    fecharModal() {
      this.openComments = false; // Altere a variável "open" no componente pai
    },
    parseData (obj) {
      return Object.keys(obj).map((key) => { return {key, ...obj[key]}; }) || []
    },
    checkUserLiked (likes={}) {
      return this.user?.data?.uid in likes
    },
    checkVerificado(name){
      const list = [
        "iarapalitot",
        "jrpalitot",
        "secretaria_e_imprensa",
        "thaisabarbosa__",
        "larissasaqueiroz",
        "ops__laise",
        "felipe_sousa.adv",
        "samantha_sf",
        "andressarods",
        "ellenrls",
        "nildac.queiroz",
        "limalaly",
        "betaniasantana083",
        "vivi_g.00",
        "rhanakalline",
        "_ednaldo.neto",
      ]
      return list.includes(name)
    },
    getPostPhoto800URL(url) {
      if (url.includes('800x800')) return url;

      return url.replace('.jpg', '_800x800.jpg')
        .replace('.jpeg', '_800x800.jpeg')
        .replace('.png', '_800x800.png')

    },
    getPostPhoto390URL(url) {
      if (url.includes('390x390')) return url;

      return url.replace('.jpg', '_390x390.jpg')
        .replace('.jpeg', '_390x390.jpeg')
        .replace('.png', '_390x390.png');
    },
    getUserPhoto800URL(url) {
      if (url.includes('800x800')) return url;

      return url.replace('?alt=media', '_800x800?alt=media')
    },
    getUserPhoto390URL(url) {
      if (url.includes('390x390')) return url;

      return url.replace('?alt=media', '_390x390?alt=media')
    },
    like(post, idx) {
        if (!this.user?.data?.uid) return

        const db = getDatabase();
        if (this.checkUserLiked(post.likes)){
          // remover curtida
          remove(ref(db, 'posts/' + post.key + '/likes/' + this.user?.data?.uid));
          // delete this.posts[idx].likes[this.user?.data?.uid];
        }else{
          // curtir
          set(ref(db, 'posts/' + post.key + '/likes/' + this.user?.data?.uid), this.user?.data?.displayName);
          // this.posts[idx].likes[this.user?.data?.uid] = this.user?.data?.displayName;
        }
    },
    alert(text) {
      alert(text)
    },
    more(){
      this.limit = this.limit+20
      this.updateFeed()
    },
    updateFeed(){
      // this.posts = this.parseData(JSON.parse(`{"hash123":{"comments":{"hash":{"text":"Mensagem bonitinha para teste","user":"alovelace"}},"img":"/imgteste.png","likes":{"alovelace":""},"text":"Lorem ipsum dolor, sit amet consectetur adipisicing elit. Veritatis aliquam corporis quisquam repudiandae quo eligendi, possimus iure eos? Doloribus, officia in aut et ab autem accusamus optio. Voluptatibus, qui accusamus?","user": {"displayName":"jrpalitot", "photoURL":"https://firebasestorage.googleapis.com/v0/b/xi-ejc-sj.appspot.com/o/user%2FKlbubK99anfg30EsZDhbhxxbtK02?alt=media&token=b67062e5-2d68-40ee-8765-19a6b5c87ee0", "uid":"KlbubK99anfg30EsZDhbhxxbtK02" }}}`))

      const dbRef = ref(getDatabase());
      const postsRef = child(dbRef, 'posts');
      const queryRef = query(
        postsRef, 
        orderByChild('timestamp'),
        limitToLast(this.limit)) // Defina o número desejado de posts);

      return onValue(queryRef, (snapshot) => {
        if (snapshot.exists()) {
          const posts = [];
          snapshot.forEach((childSnapshot) => {
            const post = { key:childSnapshot.key, ...childSnapshot.val()};
            posts.push(post);
          });
          this.posts = posts.reverse();
          // this.posts = this.parseData(snapshot.val()).reverse();
        } else {
          console.log("No data available");
        }
      });

      // get(queryRef).then((snapshot) => {
      //   if (snapshot.exists()) {
      //     const posts = [];
      //     snapshot.forEach((childSnapshot) => {
      //       const post = { key:childSnapshot.key, ...childSnapshot.val()};
      //       posts.push(post);
      //     });
      //     this.posts = posts.reverse();
      //     // this.posts = this.parseData(snapshot.val()).reverse();
      //   } else {
      //     console.log("No data available");
      //   }
      // }).catch((error) => {
      //   console.error(error);
      // });

    }
  },
  mounted () {
    this.updateFeed()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.post{
  width: 100%;
  padding-bottom: 8px;
  .post-header {
    padding: 8px;
    display: grid;
    grid-template-columns: 50px auto;
    .avatar{
      background-size: cover;
      background-position: center;
      border-radius: 50%;
      border: 1px solid #333;
      width: 40px;
      height: 40px;
    }

    .icon {
      height: 15px;
      margin-left: 5px;
      margin-bottom: -2px;
    }
  }

  .post-image{
    width: 100%;
  }
  .post-img {
    background-size: cover;
    background-position: center;
    width: 100%;
    padding-bottom: 100%;
  }

  .post-actions{
    img {
      width: 55px;
      margin-bottom: -7px
    }
  }

  .post-descriptions{
    padding: 0 8px;
  }
}
a {
  color: #42b983;
}

.ver-mais{
  text-align: center;
  font-weight: bold;
  padding: 16px;
}
</style>
