

<template>
  <div v-if="post && open" class="bg">
    <div class="modal">
        <div class="header">
            <div>Comentários</div>
            <div @click="closeModal" class="fechar"><small>Fechar</small></div>
        </div>
        
        <div class="comment-box" v-if="post.comments && Object.keys(post.comments).length  >= 1">
            <div class="comment-item" v-for="c in post.comments" :key="`comment-${c.timestamp}`">
                <div>
                    <div class="comment-img" :style="`background-image: url('${ getUserPhoto390URL(c.photoURL)}'), url(${c.photoURL});`"></div>
                </div>
                <div>
                    <small>
                        <b>{{ c.displayName }}</b> {{ c.text }}
                    </small>
                </div>
            </div>
        </div>
        <div v-else>Nenhum comentário ainda</div>
        <div v-if="user?.data?.photoURL" class="send-comment">
            <div><textarea v-model="text" :placeholder="`Adicione um comentário para ${post.user?.displayName}`" class="texto" maxlength="300"></textarea></div>
            <div @click="comentar" class="comentar">Enviar</div>
        </div>
        <div v-else class="send-comment">
            Para comentar você precisa concluir seu cadastro primeiro.
        </div>
        
    </div>
  </div>
</template>

<script>
import { getDatabase, ref, set, get } from "firebase/database";

export default {
  props: {
    post: Object,
    open: Boolean,
    user: Object
  },
  data () {
    return {
        text: ''
    }
  },
  methods: {
    getUserPhoto800URL(url) {
      if (url.includes('800x800')) return url;

      return url.replace('?alt=media', '_800x800?alt=media')
    },
    getUserPhoto390URL(url) {
      if (url.includes('390x390')) return url;

      return url.replace('?alt=media', '_390x390?alt=media')
    },
    closeModal() {
        this.text = ''
      // Emitir um evento para notificar o componente pai que o modal deve ser fechado
      this.$emit('fechar-modal');
    },
    comentar() {
        this.text = this.text.replace('<', '').replace('window', '').replace('document', '')
        if (!this.text) return;

        const db = getDatabase();
        const timestamp = new Date().getTime();
        const newComment = { displayName: this.user?.data?.displayName, photoURL: this.user?.data?.photoURL, timestamp: timestamp, text: this.text }

        set(ref(db, 'posts/' + this.post.key + '/comments/' + timestamp), newComment);
        // this.post.comments[timestamp] = newComment;
        this.text = '';

        get(ref(db, 'posts/' + this.post.key + '/comments')).then((snapshot) => {
        if (snapshot.exists()) {
            this.post.comments = snapshot.val()
        } else {
          console.log("No data available");
        }
      }).catch((error) => {
        console.error(error);
      });

    }
  }
}
</script>

<style scoped  lang="scss">
.bg {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 900;
    width: 100%;
    height: 100vh;
    background-color: rgba(10,23,55,0.5);
}
.modal {
    background-color: #FFF;
    border-radius: 16px;
    border: 1px solid #DDD;
    position: fixed;
    z-index: 999;
    top: 20%;
    left: 50%;
    width: 80%;
    margin-left: -40%;

    .header {
        display: grid;
        grid-template-columns: 1fr 1fr;
        border-bottom: 1px solid #DDD;
        padding: 8px;
        .fechar {
            text-align: right;
        }
    }
    .send-comment{
        padding: 8px;
        display: grid;
        grid-template-columns: 6fr 2fr;
        
        textarea{
            width: 100%
        }
        .comentar {
            padding-top: 8px;
            font-weight: bold;
            text-align: center;
        }
    }

    .comment-box {
        padding: 8px;
        max-height: 50vh;
        overflow-y: scroll;
        .comment-item {
            display: grid;
            grid-template-columns: 1fr 6fr;
            padding: 2px;
            .comment-img{
                background-size: cover;
                background-position: center;
                border-radius: 50%;
                border: 1px solid #28e638;
                width: 20px;
                height: 20px;
            }
        }
    }
}
</style>