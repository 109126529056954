<template>
  <h4>Threads</h4>
  <carousel :items-to-show="1.2" :wrap-around="true">
    <slide v-for="slide in threads" :key="slide">
        <div class="slide-box">
            {{ slide }}
        </div>
    </slide>

    <template #addons>
      <pagination />
    </template>
  </carousel>
</template>

<script>
// If you are using PurgeCSS, make sure to whitelist the carousel CSS classes
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import { getDatabase, ref, onValue } from "firebase/database";


export default {
  name: 'App',
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data(){
    return {
        threads: null
    }
  },
  methods: {
    parseData (obj) {
      return Object.keys(obj).map((key) => { return obj[key]; }) || []
    }
  },
  mounted () {
    const db = getDatabase();

    return onValue(ref(db, '/threads/'), (snapshot) => {
        this.threads = this.parseData(snapshot.val());
    });
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
h4 {
    padding: 8px;
}
.carousel{
    --vc-pgn-width: 8px;
    --vc-pgn-height: 8px;
    --vc-pgn-margin: 4px;
    --vc-pgn-border-radius: 50%;
    --vc-pgn-background-color: #DBDBDA;
    --vc-pgn-active-color: #4192EE;

    padding-bottom: 5px;
    margin-bottom: 8px;
    .carousel__viewport {
        background-color: #F5F5F4;
    }
    .slide-box {
        margin: 16px;
        width: calc(100% - 48px);
        background-color: #FFF;
        border: 1px solid #D9D9D8;
        border-radius: 16px;
        padding: 8px;
    }
}
</style>

